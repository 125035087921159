export const epkLinks = [
  {
    id: 'bio',
    link: 'https://www.dropbox.com/scl/fi/qugjp5pkupi62pogzz37h/Allochiria-bio-EN-2025.pdf?rlkey=pfd01pg1lyaxld0gl05esudia&dl=0',
    label: 'Biography (pdf)',
  },
  {
    id: 'tour-rider',
    link: 'https://www.dropbox.com/scl/fi/op8tl7demaattant8fg7t/ALLOCHIRIA-2025-TOUR-RIDER-full.pdf?rlkey=6lgls6uehxr86ytf7upsbo8eu&dl=0',
    label: 'Tour rider 2025 (pdf)',
  },
  {
    id: 'tech-rider-tour',
    link: 'https://www.dropbox.com/scl/fi/go7pmxl2l4l5wcfdroezo/ALLOCHIRIA-2025-TECH-RIDER.pdf?rlkey=1jitkskikq3275739nndrqlm3&dl=0',
    label: 'Technical rider (pdf)',
  },
  // {
  //   link: 'https://www.dropbox.com/scl/fi/eyebqwny4nbtj2c5uzwgm/ALLOCHIRIA-2024-TECH-RIDER.pdf?rlkey=it7k5jz7oba9yt13m5akajwq7&dl=0',
  //   label: 'Technical rider (pdf)',
  // },
  // {
  //   id: 'summer-2024-tour',
  //   link: 'https://www.dropbox.com/scl/fo/gm5arit48dwhhafq2iygi/ALr4pw8tiTy5UycKVq9UKRo?rlkey=78qdhr67ape9wtywr1c4f62v4&dl=0',
  //   label: 'Summer tour 2024 posters & templates (Dropbox)',
  // },
  {
    id: 'photos',
    link: 'https://www.dropbox.com/scl/fi/wruyrtp8rs0g6ahgng2w3/allochiria-photos-2023.zip?rlkey=fkhr5cytmnyxqbbm3fktf3ov8&dl=0',
    label: 'Band photos (Dropbox)',
  },
  {
    id: 'logos',
    link: 'https://www.dropbox.com/sh/uv4ult9x0hstoyv/AADzHBc__kobC8e7WG2nZqrva?dl=0',
    label: 'Logo pack (Dropbox)',
  },
];
