// all shows - shows archive page

export const allShows = [
  {
    year: 2025,
    date: '15 FEB 2025',
    event: '',
    venue: 'An Club',
    withBands: 'Chronoboros, Bacchus Priest',
    city: 'Athens',
    country: 'GR',
  },
  {
    year: 2024,
    date: '9 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'Quantic Club',
    city: 'Bucharest',
    country: 'RO',
    withBands: 'King Solomon, Jahmolxes',
  },
  {
    year: 2024,
    date: '8 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'Rockstadt Club',
    city: 'Brasov',
    country: 'RO',
    withBands: '',
  },
  {
    year: 2024,
    date: '7 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'M2',
    city: 'Timisoara',
    country: 'RO',
    withBands: 'Paratraznet',
  },
  {
    year: 2024,
    date: '5 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'Reset Club',
    city: 'Berlin',
    country: 'DE',
    withBands: 'Black Sadhu, Ryr',
  },
  {
    year: 2024,
    date: '2 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'OCCII',
    city: 'Amsterdam',
    country: 'NL',
    withBands: 'Hate & Merda, Lijkschouwer, Death Mask',
  },
  {
    year: 2024,
    date: '1 JUN 2024',
    event: 'Summer Tour 2024',
    venue: 'Asgaard Gentbrugge',
    city: 'Ghent',
    country: 'BE',
    withBands: 'Queen(Ares), Hate & Merda, Abrahamic Liars',
  },
  {
    year: 2024,
    date: '31 MAY 2024',
    event: 'Summer Tour 2024',
    venue: 'The Black Lab',
    city: 'Lille',
    country: 'FR',
    withBands: 'Dvne, My Diligence',
  },
  {
    year: 2024,
    date: '11 MAY 2024',
    event: '"Omonoia" & "Never" 10 year anniversary show',
    venue: 'Fuzz',
    city: 'Athens',
    country: 'GR',
    withBands: 'Universe217',
  },
  {
    year: 2024,
    date: '1 MAY 2024',
    event: 'Holy Metal Fest II',
    venue: 'Circus',
    city: 'Larissa',
    country: 'GR',
    withBands: 'Telma, Potergeist, I See Ruins',
  },
  {
    year: 2024,
    date: '25 MAR 2024',
    event: '',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Botch',
  },
  {
    year: 2023,
    date: '21 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'Piwnica Nr1',
    city: 'Berlin',
    country: 'DE',
    withBands: 'Planets Collide',
  },
  {
    year: 2023,
    date: '20 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'P8²',
    city: 'Karlsruhe',
    country: 'DE',
    withBands: 'Coltaine, Daevar, The Bees Made Honey in the Vain Tree',
  },
  {
    year: 2023,
    date: '19 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'Lu15',
    city: 'Tübingen',
    country: 'DE',
    withBands: '',
  },
  {
    year: 2023,
    date: '18 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'Au Bout De Nos Reves',
    city: 'Tournai',
    country: 'BE',
    withBands: 'Coltaine, Drudge',
  },
  {
    year: 2023,
    date: '17 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'MCP Apache',
    city: "Fontaine-l'eveque",
    country: 'BE',
    withBands: 'Coltaine, Palach',
  },
  {
    year: 2023,
    date: '15 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'Café HELL',
    city: 'Diest',
    country: 'BE',
    withBands: 'Coltaine, Echo Solar Void',
  },
  {
    year: 2023,
    date: '14 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'Chinastraat',
    city: 'Ghent',
    country: 'BE',
    withBands: 'Coltaine, Echo Solar Void',
  },
  {
    year: 2023,
    date: '13 OCT 2023',
    event: 'Commotion Tour 2023',
    venue: 'The Cave',
    city: 'Amsterdam',
    country: 'NL',
    withBands: 'Coltaine, Love Your Witch',
  },
  {
    year: 2023,
    date: '4 AUG 2023',
    event: 'Rockstadt Extreme Fest',
    venue: '',
    city: 'Rasnov',
    country: 'RO',
    withBands:
      'Meshuggah, Arch Enemy, In Flames, Architects, Zeal & Ardor, The Ocean, While She Sleeps, Employed to Serve, Villagers of Ioannina City and more',
  },
  {
    year: 2023,
    date: '16 JUL 2023',
    event: 'New Long Fest',
    venue: '',
    city: 'Nea Makri',
    country: 'GR',
    withBands:
      'Sakis Tolis, Abyssus, Blame Kandinsky, Mammock, Euphrosyne, The Vulcan Itch',
  },
  {
    year: 2023,
    date: '26 MAY 2023',
    event:
      'Commotion Tour 2023 (double release show for "Commotion" and "Barren Point")',
    venue: 'Gagarin 205',
    withBands: 'Mass Culture, Their Methlab',
    city: 'Athens',
    country: 'GR',
  },
  {
    year: 2023,
    date: '25 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Skyland',
    withBands: 'Their Methlab',
    city: 'Larissa',
    country: 'GR',
  },
  {
    year: 2023,
    date: '24 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Singles Bar',
    withBands: 'Their Methlab',
    city: 'Sofia',
    country: 'BG',
  },

  {
    year: 2023,
    date: '23 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'CK13',
    withBands: 'Their Methlab',
    city: 'Novi Sad',
    country: 'SER',
  },

  {
    year: 2023,
    date: '22 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Analog Music Hall',
    withBands: 'Their Methlab',
    city: 'Budapest',
    country: 'HU',
  },
  {
    year: 2023,
    date: '21 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Klubovna Cultural Center',
    withBands: 'Their Methlab',
    city: 'Prague',
    country: 'CZ',
  },
  {
    year: 2023,
    date: '19 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Rockhaus Salzburg',
    withBands: 'Their Methlab',
    city: 'Salzburg',
    country: 'AT',
  },

  {
    year: 2023,
    date: '17 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Kinky Star',
    withBands: 'Their Methlab',
    city: 'Ghent',
    country: 'BE',
  },
  {
    year: 2023,
    date: '15 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'EKH',
    withBands: 'Their Methlab, Godsleep',
    city: 'Vienna',
    country: 'AT',
  },

  {
    year: 2023,
    date: '14 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Quantic Club',
    withBands: 'Their Methlab',
    city: 'Bucharest',
    country: 'RO',
  },
  {
    year: 2023,
    date: '13 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Hardward Pub',
    withBands: 'Their Methlab',
    city: 'Cluj',
    country: 'RO',
  },

  {
    year: 2023,
    date: '12 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Manufactura Club',
    withBands: 'Their Methlab',
    city: 'Timișoara',
    country: 'RO',
  },
  {
    year: 2023,
    date: '11 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'AKC Fuzz',
    withBands: 'Their Methlab',
    city: 'Niš',
    country: 'SER',
  },
  {
    year: 2023,
    date: '10 MAY 2023',
    event: 'Commotion Tour 2023',
    venue: 'Eightball',
    withBands: 'Their Methlab',
    city: 'Thessaloniki',
    country: 'GR',
  },
  {
    year: 2023,
    date: '22 APR 2023',
    event:
      'Solidarity event for the funancial support of rape victim (instrumental set)',
    venue: 'Astra (Vironas)',
    withBands: 'Egoera, Mammock, Mass Culture, My Own Treehouse',
    city: 'Athens',
    country: 'GR',
  },
  {
    year: 2023,
    date: '18 MAR 2023',
    event: '',
    venue: 'BlackBox',
    city: 'Athens',
    country: 'GR',
    withBands: 'Blame Kandinsky',
  },
  {
    year: 2023,
    date: '28 JAN 2023',
    event: '',
    venue: 'An Club',
    city: 'Athens',
    country: 'GR',
    withBands: 'Sadhus, Plankton',
  },
  {
    year: 2022,
    date: '18 NOV 2022',
    event: '',
    venue: 'Mundo Nuevo Squat',
    city: 'Thessaloniki',
    country: 'GR',
    withBands: 'Their Methlab',
  },
  {
    year: 2022,
    date: '10 SEP 2022',
    event: 'FarmAttack Vol 5 (instrumental set)',
    venue: "Boubi's Ranch",
    city: 'Pirgos',
    country: 'GR',
    withBands:
      'Amken, Khirki, Krotalias, Baladesert, Opium Curs, Black Stone Machine, Saico Devastation ',
  },
  {
    year: 2022,
    date: '22 JUL 2022',
    event: 'Rockwave Festival',
    venue: 'Terra Vibe',
    city: 'Malakasa',
    country: 'GR',
    withBands: 'Social Distortion, Amenra, Krotalias, Half Gramme of Soma',
  },
  {
    year: 2022,
    date: '7 MAY 2022',
    event: '',
    venue: 'An Club',
    city: 'Athens',
    country: 'GR',
    withBands: 'Their Methlab, Khirki',
  },
  {
    year: 2022,
    date: '22 JAN 2022',
    event: '',
    venue: 'An Club',
    city: 'Athens',
    country: 'GR',
    withBands: 'Their Methlab, Khirki',
    cancelled: true,
  },
  {
    year: 2020,
    date: '28 MAR 2020',
    event: 'European Spring Tour',
    venue: 'TBA',
    city: 'Lille',
    country: 'FR',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '27 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Boiler Room',
    city: 'Tilburg',
    country: 'NL',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '26 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Antwerp Music City',
    city: 'Antwerp',
    country: 'BE',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '25 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Kinky Star',
    city: 'Ghent',
    country: 'BE',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '21 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Haus Projekt',
    city: 'Tubingen',
    country: 'DE',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '20 MAR 2020',
    event: 'European Spring Tour',
    venue: 'The Bruch Brothers',
    city: 'Lucern',
    country: 'CH',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '19 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Villa Occupato',
    city: 'Milan',
    country: 'IT',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '17 MAR 2020',
    event: 'European Spring Tour',
    venue: 'MKC Kombinat',
    city: 'Belgrad',
    country: 'SRB',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '16 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Capcana',
    city: 'Timisoara',
    country: 'RO',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '15 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Soundart Festival',
    city: 'Bucharest',
    country: 'RO',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '14 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Soundart Festival',
    city: 'Cluj',
    country: 'RO',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '13 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Live and Loud',
    city: 'Sofia',
    country: 'BG',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '12 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Eightball',
    city: 'Thessaloniki',
    country: 'GR',
    withBands: '',
    cancelled: true,
  },
  {
    year: 2020,
    date: '11 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Nevermind Music Room',
    city: 'Larissa',
    country: 'GR',
    withBands: 'Aerosol',
  },
  {
    year: 2020,
    date: '8 MAR 2020',
    event: 'European Spring Tour',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Riverside, Verbal Delirium',
  },
  {
    year: 2019,
    date: '26 DEC 2019',
    event: 'Templefest',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'Half Gramme of Soma, Message in a Cloud, Devil Flower Mantis',
  },
  {
    year: 2019,
    date: '10 OCT 2019',
    event: '',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'Deaf Kids, Mavro Gala',
  },
  {
    year: 2019,
    date: '13 APR 2019',
    event: '',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'Harakiri for the Sky, Message in a Cloud',
  },
  {
    year: 2019,
    date: '12 JAN 2019',
    event: '',
    venue: 'Stage',
    city: 'Larissa',
    country: 'GR',
    withBands: 'Poem, Stoned spirit, Stories to tell',
  },
  {
    year: 2019,
    date: '11 JAN 2019',
    event: 'Cult Of The Amps Vol. 1 (Omonoia 5 year anniversary show)',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Afformance, Amniac, Caldera',
  },
  {
    year: 2019,
    date: '10 JAN 2019',
    event: '',
    venue: 'Ghetto',
    city: 'Patras',
    country: 'GR',
    withBands: 'Afformance, Nochnoy Dozor',
  },
  {
    year: 2018,
    date: '25 OCT 2018',
    event: '',
    venue: 'Kyttaro',
    city: 'Athens',
    country: 'GR',
    withBands: 'Minsk',
  },
  {
    year: 2018,
    date: '4 AUG 2018',
    event: 'Rockstadt Extreme Fest',
    venue: '',
    city: 'Rasnov',
    country: 'RO',
    withBands:
      'Converge, In Flames, Dying Fetus, Brujeria, Fleshgod Apocalypse, Full of Hell, Obscure Sphinx, and more',
  },
  {
    year: 2018,
    date: '3 JUN 2018',
    event: '',
    venue: 'Temple',
    city: 'Athens',
    country: 'GR',
    withBands: 'we.own.the.sky',
  },

  {
    year: 2018,
    date: '13 MAY 2018',
    city: 'Berlin',
    country: 'DE',
    venue: 'ZGK',
    event: 'European tour 2018',
  },
  {
    year: 2018,
    date: '12 MAY 2018',
    city: 'Eisenberg',
    country: 'DE',
    venue: 'H2O Turnfest',
    event: 'European tour 2018',
    withBands: '',
  },
  {
    year: 2018,
    date: '11 MAY 2018',
    city: 'Prague',
    country: 'CZ',
    venue: 'Klinika',
    event: 'European tour 2018',
    withBands: '',
  },
  {
    year: 2018,
    date: '10 MAY 2018',
    city: 'Vienna',
    country: 'AT',
    venue: 'EKH',
    event: 'European tour 2018',
    withBands: '',
  },
  {
    year: 2018,
    date: '9 MAY 2018',
    city: 'Nuremberg',
    country: 'DE',
    venue: 'Aan den Rumpen',
    event: 'European tour 2018',
    withBands: '',
  },
  {
    year: 2018,
    date: '5 MAY 2018',
    city: 'Lille',
    country: 'FR',
    venue: 'Le Biplan',
    event: 'European tour 2018',
    withBands: 'Mortier, Decasia',
    cancelled: true,
  },
  {
    year: 2018,
    date: '2 MAR 2018',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: '',
    withBands: 'Sofia Sarri, Chronoboros',
  },
  {
    year: 2017,
    date: '6 OCT 2017',
    city: 'Athens',
    country: 'GR',
    venue: 'Iera Odos',
    event: 'Desertfest Athens',
    withBands:
      'Graveyard, Colour Haze, Radio Moscow, Saint Vitus, Church of Misery, Planet of Zeus, Puta Volcano, Krause, and more',
  },
  {
    year: 2017,
    date: '16 JUL 2017',
    city: 'Nea Makri',
    country: 'GR',
    venue: 'Cultural Center Nea Makri',
    event: 'New Long Fest',
    withBands:
      'Blame Kandinsky, SuperPuma, Nochnoy Dozor, Amniac, Puta Volcano, Hail Spirit Noir, Void Droid and more',
  },
  {
    year: 2017,
    date: '27 APR 2017',
    event: '',
    venue: 'Fuzz',
    city: 'Athens',
    country: 'GR',
    withBands: 'Toundra, Their Methlab',
  },

  {
    year: 2017,
    date: '20 MAR 2017',
    city: 'Ioannina',
    country: 'GR',
    venue: 'Basement of Noise',
    event: '',
    withBands: '',
  },
  {
    year: 2017,
    date: '19 MAR 2017',
    city: 'Volos',
    country: 'GR',
    venue: 'Matsaggou squat',
    event: '',
    withBands: 'Noir, Sons of Hogs, Ενδορφίνες',
  },
  {
    year: 2017,
    date: '18 MAR 2017',
    city: 'Thessaloniki',
    country: 'GR',
    venue: 'Eightball',
    event: '',
    withBands: 'Universe217, Creshna',
  },
  {
    year: 2017,
    date: '17 MAR 2017',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: 'Throes release show',
    withBands: 'Sadhus, Blame Kandinsky',
  },
  {
    year: 2016,
    date: '15 JAN 2016',
    city: 'Rethimno',
    country: 'GR',
    venue: 'Periigitiki Leschi',
    withBands: 'Harakiri for the Sky, InScissors',
    event: '',
  },
  {
    year: 2015,
    date: '7 NOV 2015',
    city: 'Athens',
    country: 'GR',
    venue: 'Romantso',
    withBands: 'Sun of Nothing',
    event: '',
  },
  {
    year: 2015,
    date: '3 JUN 2015',
    city: 'Karditsa',
    country: 'GR',
    venue: 'TEI Karditsa',
    withBands: '',
    event: '',
  },

  {
    year: 2015,
    date: '26 APR 2015',
    event: '',
    venue: 'Kyttaro',
    city: 'Athens',
    country: 'GR',
    withBands: 'Altar of Plagues, Year of no Light, Diablery',
  },

  {
    year: 2015,
    date: '30 MAR 2015',
    city: 'Thessaloniki',
    country: 'GR',
    venue: 'Biologica squat',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '29 MAR 2015',
    city: 'Sofia',
    country: 'BG',
    venue: 'Bar Zar',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '28 MAR 2015',
    city: 'Bucharest',
    country: 'RO',
    venue: 'Question Mark',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '27 MAR 2015',
    city: 'Brasov',
    country: 'RO',
    venue: 'Rockstadt',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '26 MAR 2015',
    city: 'Cluj',
    country: 'RO',
    venue: 'Shelter',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '25 MAR 2015',
    city: 'Budapest',
    country: 'HU',
    venue: '',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '24 MAR 2015',
    city: 'Bratislava',
    country: 'SK',
    venue: 'British Rock Stars',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '23 MAR 2015',
    city: 'Vienna',
    country: 'AT',
    venue: 'EKH',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '22 MAR 2015',
    city: 'Prague',
    country: 'CZ',
    venue: 'Chapeau Rouge',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '21 MAR 2015',
    city: 'Opole',
    country: 'PL',
    venue: 'Piekarnia',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },
  {
    year: 2015,
    date: '20 MAR 2015',
    city: 'Berlin',
    country: 'DE',
    venue: 'Koepi',
    event: 'European tour 2015',
    withBands: 'ryvulet and more',
  },

  {
    year: 2014,
    date: '27	DEC 2014',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    withBands: 'Universe217, Cosmic Plunge',
  },
  {
    year: 2014,
    date: '15	NOV 2014',
    city: 'Rethimno',
    country: 'GR',
    venue: 'Periigitiki Leschi',
    withBands: 'Universe217, Ghostpale',
  },
  {
    year: 2014,
    date: '13	NOV 2014',
    city: 'Chania',
    country: 'GR',
    venue: 'Astra',
    withBands: "Universe217, Potter's Field",
  },
  {
    year: 2014,
    date: '8	NOV 2014',
    city: 'Patras',
    country: 'GR',
    venue: 'Memphis Rock Bar',
    withBands: 'Hedvika, Void Droid',
  },
  {
    year: 2014,
    date: '27	SEP 2014',
    city: 'Volos',
    country: 'GR',
    venue: 'Termita squat',
    withBands: 'Mass Culture, Kataxnia, blank page, stheno',
  },
  {
    year: 2014,
    date: '8	SEP 2014',
    city: 'Lamia',
    country: 'GR',
    venue: 'Bodrum Club',
    withBands: 'Poem',
  },
  {
    year: 2014,
    date: '13	AUG 2014',
    city: 'Rasnov',
    country: 'RO',
    venue: '',
    event: 'Rockstadt Extreme Fest',
    withBands:
      'Obituary, Behemoth, Sodom, Katatonia, Rotting Christ, Fleshgod Apocalypse, Misery Index, The Agonist, Mors Principium Est, Infected Rain, the Lumberjack Feedback, and more',
  },
  {
    year: 2014,
    date: '20	JUL 2014',
    city: 'Nea Makri',
    country: 'GR',
    venue: 'Cultural Center Nea Makri',
    event: 'New Long Fest',
    withBands: `1000mods, Aenaon, Stereo Animal, 45rats, Konkave, full house brew crew, Automaton, Speedblow, Poem, 
      Above us the Waves, Kin Beneath Chorus, Still Falling, Revenge of the Giant Face, Blame Kandinsky, Hostage, Since(time)`,
  },
  {
    year: 2014,
    date: '4	JUL 2014',
    city: 'Lixouri',
    country: 'GR',
    venue: 'TEI Lixouri',
    event: '9th Independent Festival',
    withBands: 'Amken, Beeranhas, Amanita Moscaria, Yankuli',
  },

  {
    year: 2014,
    date: '28 MAY 2014 2014',
    event: '',
    venue: 'Gagarin 205',
    city: 'Athens',
    country: 'GR',
    withBands: 'Deafheaven',
  },

  {
    year: 2014,
    date: '30 APR 2014',
    city: 'Athens',
    country: 'GR',
    venue: 'Zero',
    withBands: 'Jungbluth, Ruined Families, Lentic Waters',
  },
  {
    year: 2014,
    date: '25 APR 2014',
    city: 'Thessaloniki',
    country: 'GR',
    venue: 'Biotexnia',
    withBands: 'Vygotsky Circle',
  },
  {
    year: 2014,
    date: '24 APR 2014',
    city: 'Larissa',
    country: 'GR',
    venue: 'Stage',
    withBands: 'Planet of Zeus, Beggar’s Blues Diaries',
  },
  {
    year: 2014,
    date: '8 MAR 2014',
    city: 'Athens',
    country: 'GR',
    venue: 'Leschi Ipogios K94',
    withBands: 'Thebeist, Kalpa, Mass Culture',
  },
  {
    year: 2014,
    date: '8 FEB 2014',
    city: 'Athens',
    country: 'GR',
    venue: '7sins',
    withBands: 'Automaton, Pools Of Mercury, Spacement',
  },
  {
    year: 2014,
    date: '7 FEB 2014',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    event: 'A Post Jumboree #1',
    withBands: '	Amniac, Distorted Function',
  },
  {
    year: 2014,
    date: '1 FEB 2014',
    city: 'Athens',
    country: 'GR',
    venue: '',
    event: 'Smoking Sessions Vol. 2',
    withBands: 'Korsikov',
  },
  {
    year: 2014,
    date: '4 JAN 2014',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    withBands: 'Adolf Plays The Jazz, The Voldera Cult',
    event: 'Omonoia release show',
  },

  {
    year: 2013,
    date: '28 SEP 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: 'DEFCON Fest 5',
  },
  {
    year: 2013,
    date: '20 SEP 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    withBands: 'Universe217, Agnes Vein',
  },
  {
    year: 2013,
    date: '2 JUN 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'Remedy Live Club',
    withBands: 'Universe217, Hedvika',
  },

  {
    year: 2013,
    date: '17 MAY 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    withBands: 'Lucky Funeral, Sadhus',
  },
  {
    year: 2013,
    date: '26 APR 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'Zero',
    withBands: 'Earth of Distrust, Amniac',
  },
  {
    year: 2013,
    date: '9 FEB 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'K44',
    withBands: 'Anal Veritas, Hedvika',
  },
  {
    year: 2013,
    date: '23 JAN 2013',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    withBands: 'Gomgoma, Minerva Superduty',
  },
  {
    year: 2012,
    date: '17 DEC 2012',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    withBands: 'Their Methlab, Gomgoma',
    cancelled: true,
  },
  {
    year: 2012,
    date: '4 AUG 2012',
    city: 'Messini',
    country: 'GR',
    venue: 'Imioro Rock Bar',
    withBands: 'Necrophobic Revulsion, Krifa Prosopa',
  },
  {
    year: 2012,
    date: '2 JUL 2012',
    city: 'Athens',
    country: 'GR',
    venue: 'six d.o.g.s.',
    withBands: 'Hedvika, Need',
  },

  {
    year: 2011,
    date: '15 JAN 2011',
    city: 'Athens',
    country: 'GR',
    venue: '7sins',
    withBands: 'The Lumberjack Feedback, The Lady Vanishes',
  },

  {
    year: 2010,
    date: '9 DEC 2010',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    withBands: 'GoodLuckWithTheBombs, Begging Shaman',
  },
  {
    year: 2010,
    date: '19 JUN 2010',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: 'Battle of the Bands (Finals) (as Parts of Unknown)',
    withBands:
      'My Own Sacrament, Winter Flame, 100 Bullets, blindspot, Total Riot',
  },
  {
    year: 2010,
    date: '14 MAR 2010',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: 'Battle of the Bands (Semi Finals) (as Parts of Unknown)',
    withBands:
      "My Own Sacrament, Winter Flame, 100 Bullets, blindspot, The Brightly Shining Sea, Born in the Earth's Maze, Born Dead, Mental Disorder, Normah",
  },
  {
    year: 2010,
    date: '31 JAN 2010',
    city: 'Athens',
    country: 'GR',
    venue: 'Sin City (as Parts of Unknown)',
    event: '',
    withBands: 'Stonenrow, Cantrecord, Grassroll',
  },

  {
    year: 2009,
    date: '13 DEC 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'An Club',
    event: 'Battle of the Bands (as Parts of Unknown)',
    withBands:
      'Normah, Blackout Time, Reprisal Noise, L.I.E, papersmile, desertion',
  },
  {
    year: 2009,
    date: '29 NOV 2009',
    city: 'Athens',
    country: 'GR',
    venue: '7sins (as Parts of Unknown)',
    event: 'Melting down the horizon',
    withBands: 'A.T.M, Necrophobic Revulsion, Piercing Flames',
  },
  {
    year: 2009,
    date: '1 NOV 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'Kyttaro (as Parts of Unknown)',
    event: '',
    withBands: 'J. Allen, The Virgin Steve, Bad Bid, Way Out',
  },
  {
    year: 2009,
    date: '11 JUL 2009',
    city: 'Kalamata',
    country: 'GR',
    venue: 'Gipedo mpasket N.O.K. (as Parts of Unknown)',
    event: '',
    withBands: 'Intro, Jam Project',
  },
  {
    year: 2009,
    date: '19 JUN 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'Onoma tou rodou (as Parts of Unknown)',
    event: '',
    withBands:
      'Mannerism, Her Dark Burial, The Virgin Steve, Teddy Bums, Dronne',
  },
  {
    year: 2009,
    date: '10 APR 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'Onoma tou rodou (as Parts of Unknown)',
    event: '',
    withBands: 'Steps To Regret, Anassa, Faceless',
  },
  {
    year: 2009,
    date: '22 MAR 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'Texas (as Parts of Unknown)',
    event: '',
    withBands: 'Earth of Distrust, Necrophobic Revulsion, Asm of Karawan',
  },
  {
    year: 2009,
    date: '18 JAN 2009',
    city: 'Athens',
    country: 'GR',
    venue: 'Rodeo (as Parts of Unknown)',
    event: '',
    withBands: 'Year of Zero, Dronne, Spiral God',
  },
];
