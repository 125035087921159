import styled from 'styled-components';

export const SingleShow = ({ show }) => {
  let text = `${show.date} - ${show.city}, ${show.country} @ ${
    show.venue ? `${show.venue}, ` : ''
  }${show.event ? `${show.event}` : ''}${
    show.withBands ? ` w/ ${show.withBands}` : ''
  }`;

  if (text.slice(-2) === ', ') {
    // remove trailing comma
    text = text.substring(0, text.length - 2);
  }

  if (show.cancelled) {
    return (
      <p>
        <del>{text}</del>
      </p>
    );
  }

  return (
    <p>
      {text}{' '}
      {show?.tickets && (
        <Link
          href={show.tickets}
          target="_blank"
          rel="noreferrer"
          aria-label="Click here for tickets"
        >
          [tickets]
        </Link>
      )}
    </p>
  );
};

const Link = styled.a`
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
